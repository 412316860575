import type { Order } from '@commercelayer/sdk';
import axios, { isAxiosError, type AxiosRequestConfig } from 'axios';

import type { AttributionData } from 'checkout/types/checkout';
import type { PaymentPlanResponse } from 'pages/api/order/payment-plan/[id].page';
import type { SubscriptionRes } from 'pages/api/order/subscription/[id].page';
import { buildHeaders } from 'shared/infra/account/api/services';
import api from 'shared/infra/api/client';
import { authedAxios } from 'shared/infra/http';
import logger from 'shared/services/logger';
import type { PaymentPlan } from 'shared/services/PaymentPlanService';
import type {
  PaymentInstrument,
  Subscription,
} from 'shared/services/ProductService';
import type { Maybe } from 'types/util';

export type CLOrderApiResponse = {
  data: {
    id: Order['id'];
    attributes: Partial<Order>;
  };
};

export interface ValidationError extends Error {
  title: string;
  code: string;
  detail: string;
  metadata: Record<string, unknown>;
}

export interface ValidationResponse {
  data: {
    attributes: {
      ok: boolean;
      errors: ValidationError[];
    };
  };
}

export type PaymentInstrumentRes = {
  data: PaymentInstrument[];
};

export type CreatePaymentInstrumentCheckoutRequest = {
  data: {
    type: string;
    attributes: {
      currency_code: string;
      redirect_url: string;
    };
  };
};

export type PaymentInstrumentCheckoutResponse = {
  data: {
    id: string;
    type: string;
    attributes: {
      currency_code: string;
      status: string;
      checkout_id: string;
      redirect_url: string;
    };
  };
};

export type ReplacePaymentInstrumentRequest = {
  data: {
    type: PaymentInstrument['type'];
    attributes: {
      checkout_id: string;
    };
  };
};

export type ReplacePaymentInstrumentResponse = {
  data: PaymentInstrument;
};

export type PostChargeResponse = {
  success: boolean;
  error?: string;
};

type ChargeResponse = {
  attributes: unknown;
  data: ChargeData;
};

type ChargeData = {
  id: string;
  type: string;
  attributes: ChargeAttributes;
};

type ChargeAttributes = {
  success: boolean;
};

export type ChargeErrResponse = {
  errors: ChargeErr[];
};

type ChargeErr = {
  title: string;
  code: string;
  detail: string;
};

export const putPlaceOrder = async (
  orderId: Order['id'],
  attributionData: AttributionData,
): Promise<unknown> => {
  const response = await authedAxios.put<unknown>(
    `/api/order/place/${orderId}`,
    {
      attributionData,
    },
  );
  return response.data;
};

export const postUnplaceOrder = async (
  orderId: Order['id'],
): Promise<Order> => {
  const response = await authedAxios.post<CLOrderApiResponse>(
    `/api/order/unplace/${orderId}`,
  );

  // reconstructing CL sdk response from the CL API
  return {
    id: response.data.data.id,
    ...response.data.data.attributes,
    // TODO - includes
  } as Order;
};

export const postUnplaceOrderToEcomPlatform = async (
  orderId: string,
  conf?: AxiosRequestConfig,
): Promise<CLOrderApiResponse> => {
  const response = await api.post<CLOrderApiResponse>(
    `${process.env.APIGW_URL}/storefront/orders/${orderId}/unplace`,
    {},
    conf,
  );

  return response.data;
};

export const postValidateOrder = async (
  orderId: string,
  merchantCode: string,
): Promise<ValidationResponse> => {
  const { data } = await authedAxios.post(
    `/api/merchants/${merchantCode}/orders/${orderId}/validations`,
  );

  return data as ValidationResponse;
};

export const getOrderSubscription = async (
  orderId: string,
): Promise<Subscription | null> => {
  try {
    const { data } = await axios.get<Subscription>(
      `/api/order/subscription/${orderId}`,
    );

    return data;
  } catch (err) {
    if (axios.isAxiosError(err) && err.response?.status === 404) {
      return null;
    }

    throw err;
  }
};

export const getPaymentInstruments = async (
  merchantCode: string,
  currencyCode: string,
): Promise<PaymentInstrument[]> => {
  try {
    const response = await authedAxios.get<PaymentInstrumentRes>(
      `/api/merchants/${merchantCode}/payment-instruments?currency_code=${currencyCode}`,
    );
    const { data } = response;

    return data.data;
  } catch (err) {
    logger.error(
      err,
      `Could not get payment instruments: ${
        isAxiosError(err) ? err.message : ''
      }`,
    );

    return [];
  }
};

export const createPaymentInstrumentCheckout = async ({
  merchantCode,
  currencyCode,
  redirectUrl,
}: {
  merchantCode: string;
  currencyCode: string;
  redirectUrl: string;
}): Promise<Maybe<PaymentInstrumentCheckoutResponse['data']>> => {
  try {
    const body: CreatePaymentInstrumentCheckoutRequest = {
      data: {
        type: 'payment_instrument_checkouts',
        attributes: {
          currency_code: currencyCode,
          redirect_url: redirectUrl,
        },
      },
    };
    const response = await authedAxios.post<
      CreatePaymentInstrumentCheckoutRequest,
      { data: PaymentInstrumentCheckoutResponse }
    >(`/api/merchants/${merchantCode}/payment-instrument-checkouts`, body);
    return response.data.data;
  } catch (err) {
    logger
      .withContext({ tags: { merchantCode, currencyCode } })
      .error(err, "Couldn't create payment instrument checkout");
  }
  return null;
};

export const createPaymentInstrumentCheckoutInEcomPlatform = async (args: {
  accessToken: string;
  merchantCode: string;
  currencyCode: string;
  redirectUrl: string;
}): Promise<Maybe<PaymentInstrumentCheckoutResponse['data']>> => {
  const { accessToken, merchantCode, currencyCode, redirectUrl } = args;
  try {
    const headers = buildHeaders(accessToken, {
      'Content-Type': 'application/vnd.api+json',
    });
    const response = await api.post<
      CreatePaymentInstrumentCheckoutRequest,
      PaymentInstrumentCheckoutResponse
    >(
      `${process.env.APIGW_URL}/storefront/merchants/${merchantCode}/payment-instrument-checkouts`,
      {
        data: {
          type: 'payment_instrument_checkouts',
          attributes: {
            currency_code: currencyCode,
            redirect_url: redirectUrl,
          },
        },
      },
      {
        headers,
        otelAttributes: {
          'http.route': `/api/merchants/${merchantCode}/payment-instrument-checkouts`,
        },
      } as AxiosRequestConfig,
    );

    return response.data;
  } catch (err) {
    logger
      .withContext({ tags: { merchantCode, currencyCode } })
      .error(err, "Couldn't create payment instrument checkout");
  }
  return null;
};

export const fetchPaymentInstrumentCheckout = async ({
  merchantCode,
  checkoutId,
}: {
  merchantCode: string;
  checkoutId: string;
}): Promise<Maybe<PaymentInstrumentCheckoutResponse['data']>> => {
  try {
    const response = await authedAxios.get<
      PaymentInstrumentCheckoutResponse['data']
    >(
      `/api/merchants/${merchantCode}/payment-instrument-checkouts/${checkoutId}`,
    );

    return response.data;
  } catch (err) {
    logger.error(err, "Couldn't fetch payment instrument checkout");
  }
  return null;
};

export const fetchPaymentInstrumentCheckoutFromEcomPlatform = async ({
  merchantCode,
  checkoutId,
  accessToken,
}: {
  merchantCode: string;
  checkoutId: string;
  accessToken: string;
}): Promise<Maybe<PaymentInstrumentCheckoutResponse['data']>> => {
  try {
    const headers = buildHeaders(accessToken);
    const response = await api.get<PaymentInstrumentCheckoutResponse>(
      `${process.env.APIGW_URL}/storefront/merchants/${merchantCode}/payment-instrument-checkouts/${checkoutId}`,
      {
        headers,
        otelAttributes: {
          'http.route': `/api/merchants/${merchantCode}/payment-instrument-checkouts/${checkoutId}`,
        },
      } as AxiosRequestConfig,
    );

    return response.data.data;
  } catch (err) {
    logger.error(err, "Couldn't fetch payment instrument checkout");
  }
  return null;
};

export const getOrderPaymentPlan = async (
  orderId: string,
): Promise<PaymentPlan | null> => {
  try {
    const { data } = await axios.get<PaymentPlan>(
      `/api/order/payment-plan/${orderId}`,
    );

    return data;
  } catch (err) {
    if (axios.isAxiosError(err) && err.response?.status === 404) {
      return null;
    }

    throw err;
  }
};

export const replacePaymentInstrument = async ({
  merchantCode,
  checkoutId,
  instrumentType,
}: {
  merchantCode: string;
  checkoutId: string;
  instrumentType: PaymentInstrument['type'];
}): Promise<PaymentInstrument> => {
  const body: ReplacePaymentInstrumentRequest = {
    data: {
      type: instrumentType,
      attributes: {
        checkout_id: checkoutId,
      },
    },
  };

  try {
    const response = await authedAxios.post<
      ReplacePaymentInstrumentRequest,
      { data: ReplacePaymentInstrumentResponse }
    >(`/api/merchants/${merchantCode}/payment-instruments`, body);

    return response.data.data;
  } catch (err) {
    logger
      .withContext({ tags: { merchantCode } })
      .error(err, "Couldn't replace payment instrument");

    throw err;
  }
};

export const replacePaymentInstrumentInEcomPlatform = async (args: {
  accessToken: string;
  merchantCode: string;
  checkoutId: string;
  instrumentType: PaymentInstrument['type'];
}): Promise<Maybe<ReplacePaymentInstrumentResponse['data']>> => {
  const { accessToken, merchantCode, checkoutId, instrumentType } = args;
  try {
    const headers = buildHeaders(accessToken, {
      'Content-Type': 'application/vnd.api+json',
    });
    const response = await api.post<
      ReplacePaymentInstrumentRequest,
      ReplacePaymentInstrumentResponse
    >(
      `${process.env.APIGW_URL}/storefront/merchants/${merchantCode}/payment-instruments`,
      {
        data: {
          type: instrumentType,
          attributes: {
            checkout_id: checkoutId,
          },
        },
      },
      {
        headers,
        otelAttributes: {
          'http.route': `/api/merchants/${merchantCode}/payment-instruments`,
        },
      } as AxiosRequestConfig,
    );

    return response.data;
  } catch (err) {
    logger
      .withContext({ tags: { merchantCode } })
      .error(err, "couldn't replace payment instrument");
    return null;
  }
};

export const getOrderPaymentPlanFromEcomPlatform = async (
  orderId: string,
): Promise<PaymentPlan | null> => {
  try {
    const { data: paymentPlanData } = await api.get<PaymentPlanResponse>(
      `${process.env.APIGW_URL}/storefront/orders/${orderId}/payment-plan`,
    );

    return paymentPlanData.data.attributes;
  } catch (err) {
    if (axios.isAxiosError(err) && err.response?.status === 404) {
      return null;
    }

    throw err;
  }
};

export const getOrderSubscriptionFromEcomPlatform = async (
  orderId: string,
): Promise<Subscription> => {
  const { data: subscriptionData } = await api.get<SubscriptionRes>(
    `${process.env.ECOM_PLATFORM_URL}/api/orders/${encodeURIComponent(
      orderId,
    )}/subscription`,
  );

  const { data: subscription } = subscriptionData;

  return subscription;
};

export const getPaymentInstrumentsFromEcomPlatform = async (
  merchantCode: string,
  currencyCode: string,
  accessToken: string,
): Promise<PaymentInstrumentRes> => {
  const headers = buildHeaders(accessToken);
  const { data } = await api.get<PaymentInstrumentRes>(
    `${process.env.APIGW_URL}/storefront/merchants/${merchantCode}/payment-instruments?currency_code=${currencyCode}`,
    { headers } as AxiosRequestConfig,
  );

  return data;
};

export const patchSubscriptionTaxId = async (
  orderId: string,
  taxId: string,
): Promise<Subscription> => {
  const { data } = await axios.patch<Subscription>(
    `/api/order/subscription/${orderId}`,
    { tax_id: taxId },
  );

  return data;
};

export const postChargePaymentOnFile = async (
  merchantCode: string,
  orderId: string,
): Promise<PostChargeResponse> => {
  try {
    const { data } = await authedAxios.post<ChargeResponse>(
      `/api/merchants/${merchantCode}/orders/${orderId}/charges`,
    );

    return {
      success: data?.data?.attributes?.success,
    };
  } catch (err) {
    let errMsg = err?.toString() || ('Something went wrong.' as string);
    if (axios.isAxiosError(err)) {
      switch (err.response?.status) {
        case 400:
          errMsg = 'Order is not valid for creating a charge.';
          break;
        case 403:
          errMsg =
            'Not authorized to create a charge for this merchant or order.';
          break;
        case 500: {
          const errResp = (err?.response.data as ChargeErrResponse)?.errors[0];
          errMsg = `Title: ${errResp?.title}; Detail: ${errResp?.detail}; Code: ${errResp?.code}.`;
          break;
        }
        default:
      }
    }

    return {
      success: false,
      error: errMsg,
    };
  }
};
