import type { Document } from '@contentful/rich-text-types';

import type { IValidationMessage } from 'checkout/components/ValidationMessage';
import type { PaymentMethod } from 'shared/infra/commerceLayer/types';
import type {
  IFormTextFieldFields,
  IListItemFields,
  INationalIdFields,
  INationalIdFormFields,
} from 'shared/infra/contentful/contentful';

export type OrderValidationMessages = Record<string, IValidationMessage>;

export type PaymentMethodContent = {
  method: PaymentMethod;
  label: string;
  message: string;
  id: string;
  icon: {
    url: string;
    height: number;
    width: number;
    name: string;
  };
  isAlternativePaymentMethod: boolean;
  isSubscriptionSupported: boolean; // isRecurringPaymentSupported
  isPaymentOnFileSupported: boolean;
  termsAndConditions?: Document;
};

export interface AcceptedNationalId {
  name: INationalIdFields['name'];
  type: NationalIdType;
  documentType: INationalIdFields['documentType'];
  duplicatedNationalIdErrorMessage: INationalIdFields['duplicatedNationalIdErrorMessage'];
  invalidNationalIdErrorMessage: INationalIdFields['invalidNationalIdErrorMessage'];
  inputField: IFormTextFieldFields;
}

export interface ParsedNationalIdForm {
  title: INationalIdFormFields['title'];
  name: INationalIdFormFields['name'];
  acceptedNationalIdsPersonal: AcceptedNationalId[];
  acceptedNationalIdsBusiness: AcceptedNationalId[];
  nationalIdPersonalLabel: string;
  nationalIdBusinessLabel: string;
  selectNationalIdLabel: string;
}

export interface ElectronicDocument {
  name: string;
  type: string;
}

export interface ElectronicDocumentTypeForm {
  title: string;
  acceptedElectronicDocumentTypes: ElectronicDocument[];
}

export interface ListItemParsed extends Omit<IListItemFields, 'icon'> {
  icon: {
    imageSrc: string;
    imageAltText: string;
  };
}

export enum NationalIdType {
  personal = 'personal',
  business = 'business',
}

export enum ElectronicDocumentTypesEnum {
  factura = 'factura',
  boleta = 'boleta',
}

export const makeDefaultRichtText = (text: string): Document =>
  ({
    data: {},
    content: [
      {
        data: {},
        content: [
          {
            data: {},
            marks: [],
            value: text,
            nodeType: 'text',
          },
        ],
        nodeType: 'paragraph',
      },
    ],
    nodeType: 'document',
  } as Document);
