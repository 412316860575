import type { Span } from '@opentelemetry/api';
import {
  context,
  propagation,
  SpanKind,
  SpanStatusCode,
  trace,
} from '@opentelemetry/api';
import axios, {
  isAxiosError,
  type AxiosInstance,
  type AxiosRequestHeaders,
  type AxiosResponse,
  type InternalAxiosRequestConfig,
} from 'axios';

import logger from 'shared/services/logger';

import { setOrgSpanAttributes } from '../opentelemetry/utils';

/**
 * This API client is only or use on the server side (API routes)
 */
const instance = axios.create();

export interface HttpRequestConfig extends InternalAxiosRequestConfig {
  /** Used to pass around the span entity from the request to the response to handle the lifecycle of the request */
  tracingSpan: Span;
  /** Pass additional otel parameters from the context of the executable to enrich the traces */
  otelAttributes: Record<string, string>;
}
export interface HttpClientResponse extends AxiosResponse {
  ok: boolean;
}

const attachOpenTelemetryInterceptors = (clientInstance: AxiosInstance) => {
  clientInstance.interceptors.request.use((config: HttpRequestConfig) => {
    const tracer = trace.getTracer(process.env.OTEL_API_SERVICE_NAME);
    const parentContext = context.active();
    const span = tracer.startSpan(
      `HTTP ${config.method} ${config.url}`,
      {
        kind: SpanKind.SERVER,
      },
      parentContext,
    );

    Object.keys(config.otelAttributes || {}).forEach((attr) => {
      span.setAttribute(attr, config.otelAttributes[attr]);
    });
    span.setAttribute('http.url', config.url);
    span.setAttribute('http.method', config.method);
    span.setAttribute('net.peer.name', new URL(config.url).host);
    setOrgSpanAttributes(span);
    const requestContext = trace.setSpan(parentContext, span);
    const headers = {};
    propagation.inject(requestContext, headers);

    const otelHeaders = Object.entries(headers).reduce(
      (acc, [key, value]) => ({ ...acc, [key]: value }),
      {},
    );

    return {
      ...config,
      headers: {
        ...config.headers,
        ...otelHeaders,
      } as AxiosRequestHeaders,
      tracingSpan: span,
    };
  });

  clientInstance.interceptors.response.use(
    (response: HttpClientResponse) => {
      const requestSpan = (response?.config as HttpRequestConfig).tracingSpan;

      requestSpan.setStatus({
        code: SpanStatusCode.OK,
      });
      requestSpan.setAttribute('http.status_code', response?.status);
      requestSpan.end();
      return response;
    },
    (error) => {
      if (process.env.ENVIRONMENT === 'development') {
        return Promise.reject(error);
      }

      if (isAxiosError(error)) {
        logger
          .withContext({
            extra: {
              httpMethod: error.config?.method,
              httpUrl: error.config?.url,
              status: error.response?.status,
              statusText: error.response?.statusText,
              data: error.response?.data,
            },
          })
          .info(error.message);

        const requestSpan = (error.response?.config as HttpRequestConfig)
          .tracingSpan;

        requestSpan.setStatus({
          code: SpanStatusCode.ERROR,
          message: error.response?.statusText,
        });
        requestSpan.setAttribute('http.status_code', error.response?.status);
        requestSpan.setAttribute(
          'http.status_text',
          error.response?.statusText,
        );
        requestSpan.recordException(error.message, Date.now());
        requestSpan.end();
      } else {
        logger.error(error);
      }

      return Promise.reject(error);
    },
  );
};

attachOpenTelemetryInterceptors(instance);

export default instance;
