export const LOGIN_SUCCESSFUL = 'LOGIN_SUCCESSFUL';

export const DEFAULT_RESPONSE_ERROR = {
  MESSAGE_CODE: 'DEFAULT',
  STATUS_CODE: 500,
};

export const ENDPOINTS = {
  USERS: `${process.env.APIGW_URL}/v0.1/users`,
  LOGIN: `${process.env.APIGW_URL}/oauth`,
  OIDC: `${process.env.NEXT_PUBLIC_OIDC_ISSUER}/oauth2/token`,
  CUSTOMERS: `${process.env.APIGW_URL}/storefront/customers`,
  MERCHANT_PROFILE: `${process.env.APIGW_URL}/v0.1/me`,
  REGISTRATION: `${process.env.APIGW_URL}/v0.1/me/registration`,
};
